<template lang="pug">
    #BoxVideos
        ul
            li(v-for="item in boxes")
                a(:href="item.link" target="_blank")
                    .img
                        img(:src="item.image").image
                    .content
                        p.title {{ item.title }}
</template>

<script>
import { props } from '@/mixins/component'
export default {
    name: 'playlist',
    props,
    data() {
        return {
            boxes: []
        }
    },
    mounted() {
        this.boxes = this.contents.content.map(item => ({
            ...item,
            image: `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${item.image}`
        }))
    }
}
</script>

<style lang="stylus" scoped src="./BoxVideos.styl">

</style>